export class Component {

  menuToggle(): void {
    document.addEventListener('DOMContentLoaded', () => {

      const navbar = document.getElementById('navbar');
      const burger = document.getElementById('burger');
      const menus = [navbar, burger];

      menus.forEach(menu => {
        menu.addEventListener('click', () => {
          navbar.classList.toggle('is-active');
          burger.classList.toggle('is-active');
        });
      })
      // navbar.addEventListener('click', () => {
      // });
      // burger.addEventListener('click', () => {
      //   navbar.classList.toggle('is-active');
      //   burger.classList.toggle('is-active');
      // });

    });
  }

  toggleActive(target: string, menus: any, prostopinije: string[]): void {
    const lenM = menus.length;
    const lenP = prostopinije.length;
    const id = document.getElementById(target);
    for (let i = 0; i < lenM; i++) {
      menus[i].classList.remove('is-active');
      if (target == menus[i].attributes['data-src'].value) {
        menus[i].classList.add('is-active');
      }
    }
    for (let i = 0; i < lenP; i++) {
      const t = document.getElementById(prostopinije[i]);
      if (t) {
        t.classList.remove('visible');
      }
    }
    if (id) {
      id.classList.add('visible');
    }
  }

  modalActive(target: string) {
    const modal = document.getElementById('modal');
    const img: any = document.getElementById('modal-img');
    img.src = `assets/img/lyrics/${target}.jpg`;
    modal.classList.add('is-active');
    modal.addEventListener('click', () => {
      modal.classList.remove('is-active');
    });
    document.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        modal.classList.remove('is-active');
      }
    });
  }

  emailForm(data: FormData) {
    const message = document.getElementById('message');
    const form: any = document.getElementById('contact-form');
    const send: any = document.getElementById('form-send');
    fetch('mail.php', {
      method: 'POST',
      body: data,
      cache: 'no-cache',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }
    }).then(response => response.json())
      .then(response => {
        message.innerHTML = response.message;
        message.classList.add('has-text-success');
        message.classList.remove('is-hidden');
        send.setAttribute('disabled', true);
        setTimeout(() => {
          message.innerHTML = null;
          message.classList.remove('has-text-success');
          message.classList.add('is-hidden');
          form.reset();
        }, 10000);
      }).catch(err => {
        err.json().then(response => {
          message.innerHTML = response.message;
          message.classList.add('has-text-danger');
          message.classList.remove('is-hidden');
          send.setAttribute('disabled', true);
          setTimeout(() => {
            message.innerHTML = null;
            message.classList.remove('has-text-danger');
            message.classList.add('is-hidden');
            form.reset();
          }, 10000);
        });
      });
  }

  offset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
  }

}
