import './styles.scss';
import { Component } from './components/component';
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';

const component = new Component();
component.menuToggle();

document.addEventListener('DOMContentLoaded', () => {

  const menus = document.querySelectorAll('.prostopinije-menu a');
  const images = document.querySelectorAll('.song-lyrics');
  const toTop = document.getElementById('to-top');
  const inputs: any = document.querySelectorAll('.form-input');
  const send: any = document.getElementById('form-send');
  const form: any = document.getElementById('contact-form');
  const anchors: any = document.querySelectorAll('.anchor');

  const prostopinije = [
    'uvodne-slovo',
    'liturgija',
    'menlive-casti',
    'iz-minei-prazdnicnoj',
    'poboznosti',
    'iz-triodi',
    'liturgija-pod'
  ];

  toTop.addEventListener('click', () => {
    window.scroll(0, 0);
  });

  window.addEventListener('scroll', () => {
    if (window.scrollY >= 800) {
      toTop.classList.remove('is-hidden');
    } else {
      toTop.classList.add('is-hidden');
    }
  });

  for (let i = 0; i < anchors.length; i++) {
    anchors[i].addEventListener('click', (e: any) => {
      const el = document.getElementById(e.target.attributes['data-link'].value);
      e.preventDefault();
      window.scrollTo({
        top: component.offset(el)
      });
    });
  }

  for (let i = 0; i < menus.length; i++) {
    menus[i].addEventListener('click', (e: any) => {
      component.toggleActive(e.target.attributes['data-src'].value, menus, prostopinije);
    });
  }

  for (let i = 0; i < images.length; i++) {
    images[i].addEventListener('click', (e: any) => {
      component.modalActive(e.target.attributes['data-img'].value);
      e.preventDefault();
    });
  }

  const valid = [false, false, false];
  for (let i = 0; i < 3; i++) {
    inputs[i].addEventListener('input', () => {
      if (inputs[i].checkValidity()) {
        valid[i] = true;
      } else {
        valid[i] = false;
      }
      if (valid.every(v => v === true)) {
        send.removeAttribute('disabled');
      } else {
        send.setAttribute('disabled', true);
      }
    });
  }

  form.addEventListener('submit', (e: any) => {
    e.preventDefault();
    const data = new FormData(form);
    component.emailForm(data);
  });

  new APlayer({
    container: document.getElementById('player'),
    mini: false,
    autoplay: false,
    theme: '#3684b9',
    loop: 'all',
    preload: 'auto',
    volume: 1,
    mutex: true,
    listFolded: false,
    listMaxHeight: 90,
    audio: [
      {
        name: 'Hospoď Boh',
        artist: 'MS',
        url: 'assets/songs/concert/01 - ms - hospod boh.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Trojce svjataja',
        artist: 'MS',
        url: 'assets/songs/concert/03 - ms - trojce.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Jektenija',
        artist: 'MS',
        url: 'assets/songs/concert/04 - ms - jektenija.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Proslavite Boha',
        artist: 'MS',
        url: 'assets/songs/concert/05 - ms - proslavite.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Carju nebesnyj',
        artist: 'MS',
        url: 'assets/songs/concert/06 - ms - carju.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Bludnyj syn',
        artist: 'MS',
        url: 'assets/songs/concert/07 - ms - bludnyj syn.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Trihospodi',
        artist: 'MS',
        url: 'assets/songs/concert/09 - ms - trihospodi.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Hospodi Hospodi',
        artist: 'MS',
        url: 'assets/songs/concert/10 - ms - hospodi.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Otče naš',
        artist: 'MS',
        url: 'assets/songs/concert/13 - ms - otce nas.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Toj nas spaset',
        artist: 'MS',
        url: 'assets/songs/concert/15 - ms - toj nas spaset.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Molitva pokajanna',
        artist: 'MS',
        url: 'assets/songs/concert/16 - ms - molitva.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Pomiluj mja Bože',
        artist: 'MS',
        url: 'assets/songs/concert/17 - ms - pomiluj mja.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Chvala tebi',
        artist: 'MS',
        url: 'assets/songs/concert/19 - ms - chvala tebi.mp3',
        cover: 'assets/img/logo.svg'
      },
      {
        name: 'Tebi podobajet',
        artist: 'MS',
        url: 'assets/songs/concert/20 - ms - tebi podobajet.mp3',
        cover: 'assets/img/logo.svg'
      }
    ]
  });

});





